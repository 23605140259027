<template>
  <div class="card-body">
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-row class="headerTable-div">
          <v-col md="4" sm="12">
            <p class="title main-color" style="text-align: left">
              {{ page_title }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mb-7">
          <v-col md="5" sm="12">
            <!-- Semester/Quarter -->
            <v-row>
              <v-col md="4" sm="4" class="">
                <p class="mt-2">Semester/Quarter</p>
              </v-col>
              <v-col md="8" sm="8">
                <v-autocomplete
                  :label="$t('Quarters')"
                  :items="quarters"
                  item-text="name"
                  item-value="id"
                  v-model="selectedQuarter"
                  solo
                ></v-autocomplete>
              </v-col>
            </v-row>

            <!-- subject -->
            <v-row>
              <v-col md="4" sm="4" class="">
                <p class="mt-2">subject</p>
              </v-col>
              <v-col md="8" sm="8">
                <v-autocomplete
                  :label="$t('Subject')"
                  :items="subjects"
                  item-text="subject"
                  item-value="id"
                  v-model="selectedSubject"
                  @change="getQualifiers()"
                  solo
                ></v-autocomplete>
              </v-col>
            </v-row>

            <!-- Activity -->
            <v-row>
              <v-col md="4" sm="4" class="">
                <p class="mt-2">Activity</p>
              </v-col>
              <v-col md="8" sm="8">
                <v-autocomplete
                  :label="$t('Activity')"
                  :items="activities"
                  item-text="name"
                  item-value="id"
                  v-model="selectedActivity"
                  @change="getGradeClasses()"
                  solo
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="2" cols="12"></v-col>
          <v-col md="5" cols="12">
            <!-- Grades -->
            <v-row>
              <v-col md="4" sm="4" class="">
                <p class="mt-2">Grades</p>
              </v-col>
              <v-col md="8" sm="8">
                <v-autocomplete
                  :label="$t('Grade')"
                  :items="grades"
                  item-text="grade_name"
                  item-value="id"
                  v-model="selectedGrade"
                  @change="getSubjects()"
                  solo
                ></v-autocomplete>
              </v-col>
            </v-row>

            <!-- Qualifiers -->
            <v-row>
              <v-col md="4" sm="4" class="">
                <p class="mt-2">Qualifier</p>
              </v-col>
              <v-col md="8" sm="8">
                <v-autocomplete
                  :label="$t('Qualifier')"
                  :items="qualifiers"
                  item-text="qualifier_title"
                  item-value="subject_qualifier_quarter_id"
                  v-model="selectedQualifier"
                  @change="getActiveties()"
                  solo
                ></v-autocomplete>
              </v-col>
            </v-row>

            <!-- Class -->
            <v-row>
              <v-col md="4" sm="4" class="">
                <p class="mt-2">Class</p>
              </v-col>
              <v-col md="8" sm="8">
                <v-autocomplete
                  :label="$t('Class')"
                  :items="classes"
                  item-text="name"
                  item-value="id"
                  v-model="selectedClass"
                  solo
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider
          style="background-color: #ddd !important; margin-bottom: 20px"
          class="mt-4 mb-4 main-color"
        ></v-divider>
        <v-row>
          <!-- students class -->
          <v-col v-if="items.length > 0" cols="12" md="6">
            <div class="row actions-container mt-5 mb-7">
              <div class="actions">
                <v-icon
                  v-if="!edit_mode"
                  :title="$t('Edit')"
                  style="cursor: pointer"
                  class="main-color edit-icon"
                  @click="edit_mode = true"
                  >edit</v-icon
                >
                <span
                  v-if="edit_mode"
                  class="main-color pr-3"
                  style="cursor: pointer"
                  @click="update()"
                  >{{ $t("Save") }}</span
                >
                <span
                  v-if="edit_mode"
                  style="color: #646464; cursor: pointer"
                  @click="cancel()"
                  >{{ $t("Cancel") }}</span
                >
              </div>
            </div>

            <!-- Sample Table View Mode -->
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" v-if="edit_mode">
                      <!-- <v-checkbox @click="selectAll()"></v-checkbox> -->
                    </th>
                    <th class="text-left">
                      {{ $t("Students") }}
                    </th>

                    <th
                      class=""
                      :style="
                        edit_mode
                          ? 'text-align: center !important'
                          : 'text-align: left !important'
                      "
                    >
                      {{ $t("Exam") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="edit_mode">
                    <th class="text-left">
                      <v-checkbox @click="selectAll()"></v-checkbox>
                    </th>
                    <th class="text-left">
                      {{ $t("All Class") }}
                      <p class="red--text" v-if="showErrorMessageStudentSelect">
                        {{ $t("Shoud be Select one student at least") }}
                      </p>
                    </th>

                    <th class="text-left">
                      <v-autocomplete
                        :label="$t('Select Exam')"
                        :items="exams"
                        item-text="name"
                        item-value="id"
                        v-model="selectedExam"
                        solo
                      ></v-autocomplete>
                      <div>
                        <p
                          class="red--text"
                          v-if="showErrorMessageForSelectExam"
                        >
                          {{ $t("Shoud be Select Exam") }}
                        </p>
                      </div>
                    </th>
                  </tr>
                  <tr v-for="item in items" :key="item">
                    <td v-if="edit_mode">
                      <v-checkbox v-model="item.selected"></v-checkbox>
                    </td>
                    <td>
                      {{ item.name }}
                    </td>

                    <td>{{ item.exam_name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <!-- grade -->
          <v-col v-if="showSelectExamOnGrade" cols="12" md="6">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("All Grade") }}
                    </th>
                    <th class="text-left" v-if="edit_mode_grade">
                      <v-autocomplete
                        :label="$t('Select Exam')"
                        :items="exams"
                        item-text="name"
                        item-value="id"
                        v-model="selectedExam"
                        solo
                      ></v-autocomplete>
                      <div>
                        <p
                          class="red--text"
                          v-if="showErrorMessageForSelectExam"
                        >
                          {{ $t("Shoud Be Select Exam") }}
                        </p>
                      </div>
                    </th>

                    <th class="text-left">
                      <span
                        v-if="edit_mode_grade"
                        class="main-color pr-3"
                        style="cursor: pointer"
                        @click="updateExamOnGrade()"
                        >{{ $t("Save") }}</span
                      >
                      <span
                        v-if="edit_mode_grade"
                        style="color: #646464; cursor: pointer"
                        @click="cancelTow()"
                        >{{ $t("Cancel") }}</span
                      >
                      <v-btn
                        class="modal-btn-save saveBtn"
                        @click="edit_mode_grade = true"
                        v-if="!edit_mode_grade"
                        >{{ $t("Bulk Assign") }}</v-btn
                      >
                    </th>
                  </tr>
                </thead>
              </template>
            </v-simple-table>
          </v-col>
          <v-col md="6" cols="12"></v-col>
        </v-row>
      </div>
    </div>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
// import addEditExamComponent from "../../components/modals/addEditExamComponent";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "Exams",
  // components: { addEditExamComponent },
  mixins: [validationMixin],
  data() {
    return {
      snackTime: 3000,
      snackColor: "success",
      selectAllStudent: false,
      snackText: "",
      snack: false,
      page_title: "Activity Exam Assignment",
      selectedQuarter: "",
      selectedSubject: "",
      selectedGrade: "",
      selectedQualifier: "",
      selectedActivity: "",
      selectedClass: "",
      selectedExam: "",
      edit_mode: false,
      edit_mode_grade: false,
      showErrorMessageForSelectExam: false,
      showErrorMessageStudentSelect: false,
      showSelectExamOnGrade: false,
      quarters: [],
      subjects: [],
      grades: [],
      qualifiers: [],
      activities: [],
      classes: [],
      items: [],
      exams: [],

      search: "",
      currntQuarterid: "",
      selected: [],
    };
  },
  watch: {
    selectedQuarter: {
      handler() {
        this.getGrades();
      },
      deep: true,
    },
    selectedClass: {
      handler() {
        if (this.selectedClass != "" && this.selectedClass != "all_class") {
          this.showSelectExamOnGrade = false;
          this.getClassStudents();
        } else if (this.selectedClass == "all_class") {
          this.items = [];
          this.showSelectExamOnGrade = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    cancel() {
      this.items.forEach((el) => {
        el.selected = false;
      });
      this.selectedExam = "";
      this.edit_mode = false;
      this.showErrorMessageForSelectExam = false;
      this.showErrorMessageStudentSelect = false;
      this.showSelectExamOnGrade = false;
      this.showErrorMessageForSelectExam = false;
    },
    select(item) {
      item.selected = !item.selected;
    },
    selectAll() {
      this.selectAllStudent = !this.selectAllStudent;
      this.items.forEach((el) => {
        el.selected = this.selectAllStudent;
      });
    },
    getQuarters() {
      axios
        .get(this.getApiUrl + "/exams/filter/getQuartersForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.selectedQuarter = "";
          this.selectedSubject = "";
          this.selectedGrade = "";
          this.selectedQualifier = "";
          this.selectedActivity = "";
          this.selectedClass = "";
          this.quarters = [];
          this.subjects = [];
          this.grades = [];
          this.qualifiers = [];
          this.activities = [];
          this.classes = [];
          this.quarters = response.data.data.quarters;

          this.currntQuarterid = response.data.data.currntQuarterid;
          this.selectedQuarter = response.data.data.currntQuarterid;
        });
    },
    getGrades() {
      if (this.selectedQuarter != "") {
        // performance/getGradesByRole/{quarterId}
        axios
          .get(
            this.getApiUrl +
              "/performance/getGradesByRole/" +
              this.selectedQuarter,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.selectedSubject = "";
            this.selectedGrade = "";
            this.selectedQualifier = "";
            this.selectedActivity = "";
            this.selectedClass = "";
            this.activities = [];
            this.classes = [];
            this.subjects = [];
            this.grades = [];
            this.qualifiers = [];
            this.grades = response.data.data;
          });
      }
    },
    getSubjects() {
      if (this.selectedGrade != "") {
        axios
          .get(
            this.getApiUrl +
              "/performance/getAcademicGradeSubjectsFilter/" +
              this.selectedGrade +
              "/" +
              this.selectedQuarter,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.selectedSubject = "";

            this.selectedQualifier = "";
            this.subjects = [];
            this.selectedActivity = "";
            this.selectedClass = "";
            this.activities = [];
            this.classes = [];
            this.qualifiers = [];
            this.subjects = response.data.data;
          });
      }
    },
    getQualifiers() {
      console.log(this.selectedSubject);
      if (this.selectedSubject != "") {
        axios
          .get(
            this.getApiUrl +
              "/performance/getSubjectQuarterQualifiersFilter/" +
              this.selectedQuarter +
              "/" +
              this.selectedSubject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.selectedQualifier = "";
            this.selectedActivity = "";
            this.selectedClass = "";
            this.activities = [];
            this.classes = [];
            this.qualifiers = [];
            this.qualifiers = response.data.data;
            this.getGradeClasses();
          });
      }
    },
    getActiveties() {
      if (this.selectedQualifier != "") {
        axios
          .get(
            this.getApiUrl +
              "/performance/get-qualifier-activities/" +
              this.selectedQualifier,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.selectedActivity = "";
            this.selectedClass = "";
            this.activities = [];
            this.classes = [];
            this.activities = response.data.data.activities;
          });
      }
    },
    getGradeClasses() {
      if (this.selectedGrade != "") {
        if (this.selectedClass != "") {
          this.getClassStudents();
          this.getExams();
        } else {
          axios
              .get(
                  this.getApiUrl +
                  "/performance/getGradeClasses/" +
                  this.selectedGrade,
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.token,
                      //the token is a variable which holds the token
                    },
                  }
              )
              .then((response) => {
                this.classes = [];
                this.getExams();
                this.items = [];
                this.classes = response.data.data;
              });
        }
      }
    },
    getClassStudents() {
      if (this.selectedClass != "") {
        axios
          .get(
            this.getApiUrl +
              "/performance/userRelatedClassStudents/" +
              this.selectedClass +
              "/" +
              this.selectedActivity,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.items = [];
            this.items = response.data.data;
          });
      }
    },
    // getAllData() {
    //   if (this.selectedQuarter != "") {
    //     // performance/getGradesByRole/{quarterId}
    //     axios
    //       .get(
    //         this.getApiUrl +
    //           "/performance/getGradesByRole/" +
    //           this.selectedQuarter +
    //           "?page=" +
    //           this.page,
    //         {
    //           headers: {
    //             Authorization: "Bearer " + localStorage.token,
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         this.setPaginationParameters(response);
    //         this.totalItems = response.data.data.total;
    //         this.items = [];
    //         this.items = response.data.data.data;
    //         this.loading = false;
    //       });
    //   }
    // },
    // setPaginationParameters(response) {
    //   this.length = response.data.data.last_page;
    //   if (this.length > 5) this.totalVisible = 5;
    // },
    getExams() {
      // /performance/activity/exams/{activityId}
      if (this.selectedActivity != "") {
        axios
          .get(
            this.getApiUrl +
              "/performance/activity/exams/" +
              this.selectedActivity,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.exams = [];

            this.exams = response.data.data;
          });
      }
    },
    update() {
      var errorStudentSelect = true;
      this.items.forEach((el) => {
        if (el.selected == true) {
          errorStudentSelect = false;
        }
      });
      if (this.selectedExam == "") {
        this.showErrorMessageForSelectExam = true;
      } else if (errorStudentSelect == true) {
        this.showErrorMessageStudentSelect = true;
      } else {
        this.showErrorMessageStudentSelect = false;
        this.showErrorMessageForSelectExam = false;
        let data = {
          students: this.items,
          selected_exams: this.selectedExam,
        };
        // console.log(data);
        axios
          .post(this.getApiUrl + "/performance/assign-exam-to-student", data, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              this.edit_mode = false;
              this.showErrorMessageForSelectExam = false;
              this.snack = true;
              this.snackColor = "green";
              this.snackText = "Exam Save Successfuly";
              this.getClassStudents();
            }
          });
      }
    },
    updateExamOnGrade() {
      // console.log("s");
      var exam = this.selectedExam;
      var activity = this.selectedActivity;
      var quarterId = this.selectedQuarter;
      var gradeId = this.selectedGrade;
      if (this.selectedExam == "") {
        this.showErrorMessageForSelectExam = true;
      } else {
        if (gradeId != "") {
          //send API
          var data = {
            exam_id: exam,
            activity_id: activity,
          };
          axios
            .post(
              this.getApiUrl +
                "/performance/assign-exam-to-grade-students/" +
                quarterId +
                "/" +
                gradeId,
              data,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.edit_mode_grade = false;
                this.showErrorMessageForSelectExam = false;
                this.snack = true;
                this.snackColor = "green";
                this.snackText = "Exam Save Successfuly";
              }
            });
        }
      }
    },
    cancelTow() {
      this.edit_mode_grade = false;
      this.showErrorMessageForSelectExam = false;
      this.selectedExam = "";
    },
  },
  mounted() {
    this.subject_id = this.$router.currentRoute.params.subject_id;
    this.academic_grade_id = this.$router.currentRoute.params.grade_id;
    this.getQuarters();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// end search input
.title-page {
  margin-top: 15px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.card-container {
  max-width: 80%;
  border-radius: 10px;
  position: relative;
  margin-bottom: 50px;
}
.actions-container {
  position: relative;
}
.actions {
  position: absolute;
  top: -10px;
  right: 22px;
}
.actions i,
.actions span {
  cursor: pointer;
}
.actions span {
  margin-left: 5px;
}

.title-qualifire .divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
.q-box-title {
  font-size: 29px;
  position: relative;
}

.q-box-title h2 {
  font-size: 25px;
  padding: 0;
  margin: 0;
}

.title-page {
  margin-top: 15px;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #eef3ff;
  margin-bottom: 33px;
}
.v-expansion-panel-content {
  background-color: #f7f7f7;
}
.title-panel {
  color: rgb(114, 151, 255);
  margin: 0;
  margin-top: 15px;
  padding: 0;
}
.details {
  margin-top: 6%;
  overflow: hidden;
}
.details span {
  color: #8d8a8a;
  font-size: 17px;
  font-weight: 700;
}
.details span.passing {
  float: right;
}
.v-expansion-panel-content__wrap {
  padding: 0;
}
.space-name {
  margin-top: -15px !important;
}

.betweencolon {
  position: absolute;
  top: 3rem;
  left: 5%;
}

.dur-error {
  margin-top: 0 !important;
}
.maxSize {
  font-size: 10px;
}
.text-left,
.text-left th,
.text-left td {
  text-align: left !important;
}
</style>
